<template>
  <v-row class="mb-10">
    <v-col
      cols="12"
      v-if="showHeaderActions"
    >
      <HeaderActions
        @addTemporaryProduct="addTemporary"
        @searchByReference="(e) => searchByReference(e)"
        @openAdvancedFilter="() => (isOpened = true)"
      />

      <AdvancedFilterModal
        :isOpened="isOpened"
        @addCartProductsToBudget="addCartProductsToBudget"
        @closeAdvancedFilter="() => (isOpened = false)"
      />
    </v-col>

    <v-col
      style="position: relative"
      cols="12"
      md="12"
    >
      <v-snackbar
        v-model="snackbar"
        color="red darken-1"
        width="400"
        height="100"
        centered
        timeout="-1"
        style="left: 40%; transform: translateX(-40%)"
      >
        Produto inativo ou desativado.
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
      <v-alert
        :value="successAdding"
        dense
        text
        style="position: absolute; top: -15px"
        type="success"
        transition="slide-x-transition"
        class="font-weight-bold"
      >
        Produto adicionado com sucesso!
      </v-alert>
      <div
        v-if="budget.products.length == 0"
        class="d-flex flex-column align-center"
      >
        <v-img
          src="@/assets/empty-product.svg"
          max-width="200"
        />
        <h6 class="text-h5">Nenhum produto adicionado</h6>
      </div>
      <div v-show="!isLoadingProducts">
        <div
          v-show="viewProducts"
          key="card"
        >
          <div
            v-for="(product, idx) in budgetProducts"
            :key="idx"
          >
            <ProductsSelectedCard
              :disableCard="showHeaderActions"
              @removeBudgetProduct="removeProduct(product)"
              @addBudgetProduct="
                (e) =>
                  $store.commit('setBudgetProducts', { product: e, idx: idx })
              "
              :index="idx"
              :defaultCompanyId="budget.company_id"
              :product="product"
              :companies="companies"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!viewProducts && budget.products.length > 0"
        key="message"
      >
        <div class="text-center">
          <v-icon
            color="primary"
            large
            >fas fa-boxes</v-icon
          >
        </div>
        <h6 class="text-h6 text-center py-6 px-12">
          {{ budget.products.length | lengthMsg }}
        </h6>
        <div class="text-center">
          <v-btn
            @click="$emit('showProducts')"
            small
            rounded
            color="primary"
          >
            Mostrar produtos
          </v-btn>
        </div>
      </div>
      <div v-if="isLoadingProducts">
        <v-progress-linear
          color="primary"
          indeterminate
        />
        <h4 class="text-center grey--text mt-4">Carregando...</h4>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { useBudgetEvents } from '@/main.js'
export default {
  filters: {
    lengthMsg(value) {
      if (value == 1) return 'Você possui 1 produto adicionado'

      return `Você possui ${value} produtos adicionados`
    },
  },
  components: {
    ProductsSelectedCard: () => import('./ProductsSelectedCard'),
    HeaderActions: () => import('./ProductsHeaderActions'),
    AdvancedFilterModal: () => import('./ProductsAdvancedFilterModal'),
  },
  props: {
    budget: { type: Object, default: () => {} },
    viewProducts: { type: Boolean, default: () => true },
    companies: { type: Array, required: true },
    showHeaderActions: Boolean,
  },
 
  data: () => ({
    snackbar: false,
    advancedFilterModal: false,
    successAdding: false,
    temporaryModal: false,
    fab: false,
    openCart: false,
    isOpened: false,
    panel: 1,
    isLoadingProducts: false,
    is_clicked: false,
    rows: [],
    products: [],
    cartProducts: [],
    selectedColor: null,
    printId: null,
    selectedCompany: null,
    controller: 0,
    total: [],
  }),
  computed: {
    budgetProducts() {
      return this.budget.products
    },
  },
  mounted() {
    useBudgetEvents.$on('setBudgetCompany', (value) => {
      this.selectedCompany = value
    })
    // this.searchByReference('MB00773')
  },
  methods: {
    addCartProductsToBudget(products) {
      this.isOpened = false
      products.forEach((product) => {
        this.budget.products.push({
          ...product,
          product_images: product.images,
          print_id: product.selectedPrint ? product.selectedPrint.id : null,
          vendor_finance_id: product.selectedTable.id
            ? product.selectedTable.id
            : null,
          vendor: product.product_vendors,
        })
      })
    },
    addTemporary(product) {
      this.successAdding = true
      this.budget.products.push(product)

      setTimeout(() => {
        this.successAdding = false
      }, 1000)
    },
    addToCart(product, selectedTable, rows, selectedPrint) {
      this.cartProducts.push({ ...product, selectedTable, rows, selectedPrint })
    },
    removeFromCart(idx) {
      this.cartProducts.splice(idx, 1)
    },
    clearCart() {
      this.cartProducts = []
    },
    updateBudgetProduct(idx, value) {
      this.budgetProducts[idx] = { ...this.budgetProducts[idx], ...value }
      this.$emit('setBudgetProducts', this.budgetProducts)
    },
    async searchByReference(reference) {
      try {
        const product = await this.$services.products.findProductByCode(
          reference
        )

        if (!product.status) {
          this.snackbar = true
          return
        }

        const vendorStatus = product.vendor.vendor_status

        if ([3, 4].includes(vendorStatus)) {
          const reason =
            vendorStatus === 3 ? '<b>sem estoque</b>' : '<b>fora de linha</b>'

          const message = `Produto ${reason} não pode ser adicionado ao orçamento.`

          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
          return
        }

        this.successAdding = true

        this.budget.products.push(product)

        setTimeout(() => {
          this.successAdding = false
        }, 1000)

        
      } catch (error) {
        console.log(error)
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Produto não encontrado',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
    getProducts() {
      this.$api
        .get('products/all')
        .then((res) => {
          this.products = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    updateProduct(product) {
      this.$emit('addProduct', product)
    },
    // removeProduct(product) {
    //   this.$store.commit('removeProductFromBudget', product);
    //   const productIndex = this.budget.products.findIndex(
    //     (item) => item.product_id === product.product_id
    //   );
  },
  
}
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
#filterDialog .v-speed-dial {
  position: absolute;
}

#filterDialog .v-btn--floating {
  position: relative;
}

.slide-y-enter,
.slide-y-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
.slide-y-leave-active,
.slide-y-enter-active {
  transition: all 0.2s;
}
.slide-y-leave,
.slide-y-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.custom-alert {
  background-color: #4caf50 !important;
  color: #fff !important;
  border-radius: 5px;
  padding: 10px;
}
</style>
